import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { Router } from "@reach/router"
import styled from "styled-components"
import tw from "twin.macro"

import Layout from "../components/layout"
// import SEO from "../components/seo";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2b3eb3;
  padding: 2rem 1rem;
  min-height: 100vh;
  ${tw`md:(h-screen)`}
`

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 1rem;
  width: 24rem;
  max-width: 75vw;
  margin-top: 0.5rem;
`
const Button = styled.button`
  width: 24rem;
  max-width: 75vw;
  border: none;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
`

const AboutPage = () => {
  const initialState = {
    name: "",
    email: "",
    message: "",
  }

  return (
    <Layout>
      {/* <SEO title="Hello world!" /> */}
      <Flex>
        <StyledText>
          <p>
            Hej, I'm a full stack developer from Berlin, living and working in
            Copenhagen. With a background in human nutrition and biomedical
            research, I fell in love with web development in 2019 and never
            looked back.
          </p>
          <p>
            If you are looking for someone to realize your ideas, feel free to{" "}
            <StyledLink to="/contact">reach out</StyledLink>!
          </p>
          <a
            href="/cv"
            style={{
              color: "white",
              cursor: "pointer",
              paddingRight: "1rem",
            }}
            target="_blank"
          >
            CV
          </a>
          <a
            href="https://github.com/markuskoehler360"
            style={{
              color: "white",
              cursor: "pointer",
              paddingRight: "1rem",
            }}
            target="_blank"
          >
            GitHub
          </a>
        </StyledText>
        <StyledText
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h4
            onClick={() => {
              navigate(-1)
            }}
            style={{ color: "white", cursor: "pointer", paddingLeft: "1rem" }}
          >
            Go back
          </h4>
        </StyledText>
      </Flex>
    </Layout>
  )
}

export default AboutPage
